const routes = [{
	path: '/order/order',
	component: () => import('@/views/order/order.vue'),
}, {
	path: '/order/kkOrder',
	component: () => import('@/views/order/kkOrder.vue'),
}, {
	path: '/order/medicineOrder',
	component: () => import('@/views/order/medicineOrder.vue'),
}, ]

export default routes