const routes = [{
	path: '/report/commission',
	component: () => import('@/views/report/commission.vue'),
}, {
	path: '/report/profit',
	component: () => import('@/views/report/profit.vue'),
}, {
	path: '/report/medicine',
	component: () => import('@/views/report/medicine.vue'),
}, ]

export default routes