const routes = [{
	path: '/goods/goods',
	component: () => import('@/views/goods/goods.vue'),
}, {
	path: '/goods/recommend',
	component: () => import('@/views/goods/recommend.vue'),
}, {
	path: '/goods/discount',
	component: () => import('@/views/goods/discount.vue'),
}, {
	path: '/goods/medicalExam',
	component: () => import('@/views/goods/medicalExam.vue'),
}, {
	path: '/goods/jhtProduct',
	component: () => import('@/views/goods/jhtProduct.vue'),
}, ]

export default routes